<template>
  <section class="dtc-main-section mt-4" id="">
    <header
      class="dtc-title"
      style="font-size: 20px; display: grid; place-items: start;font-family:DFKai-sb;"
    >
      附件4-3
    </header>
    <header
      class="dtc-title"
      style="font-size: 20px; display: grid; place-items: center;font-family:DFKai-sb;"
    >
      衛生福利部偏鄉護理菁英計畫
    </header>
    <header
      style="font-size: 20px; display: grid; place-items: center;font-family:DFKai-sb;border-bottom: 1px solid black;height:50px"
    >
      <div style="border:0px">
        {{ schoolInfo.schoolName }}學校{{ schoolInfo.year }}學年度{{
          schoolInfo.term == 1 ? "上" : "下"
        }}學期護理公費生總輔導紀錄表
      </div>
    </header>

    <header class="grid-6">
      <div v-for="item in names" :key="item">{{ item }}</div>
    </header>
    <main class="grid-6" v-for="(item, i) in allListItems" :key="i">
      <div>{{ i + 1 }}</div>
      <div>{{ item.StudentName }}</div>
      <div>{{ item.GradeName }}</div>
      <div>{{ item.Tutor_times }}</div>
      <div>{{ item.Remark1 }}</div>
      <div></div>
    </main>

    <footer>
      <div style="font-size:17px;font-weight: 500;padding-left:5px">
        公費生輔導問題綜整與建議:
      </div>
      <div style="font-size:17px;font-weight: 500;padding-left:5px">
        {{ recommend }}
      </div>
    </footer>
    <footer style="border:0px">
      <div style="transform: translateX(20px);">
        註：核銷時填寫個別輔導紀錄表(附表4-4)及總輔導紀錄表(附表4-3)及印領清冊明細表(附表4-2)送衛生福利部辦理核銷。
      </div>
      <div class="footerWord">
        <div class="footerWordDetail">
          總輔導紀錄表填寫人： {{ createName }}
        </div>
        <div class="footerWordDetail">導師簽名:</div>
        <div class="footerWordDetail">護理主任簽名:</div>
        <div class="footerWordDetail">
          中華民國
          <span class="footerWordTime">年</span>
          <span class="footerWordTime">月</span>
          <span class="footerWordTime">日</span>
        </div>
      </div>
    </footer>
  </section>
</template>

<script>
const names = [
  "序號",
  "姓名",
  "年級",
  "輔導次數",
  "輔導簡述(問題原因、輔導摘要、處理方式、建議事項)",
  "輔導者簽名",
];
import queryString from "query-string";

export default {
  name: "totalSpend15",
  data() {
    return {
      names,

      amount: 0,
      chineseAmount: "",
      map: {},
      allListItems: [],
      recommend: "",
      createName: "",
      schoolInfo: {},
    };
  },
  methods: {
    async getData() {
      this.schoolInfo = JSON.parse(sessionStorage.getItem("print"));
      const printInfo = JSON.parse(sessionStorage.getItem("print"));
      const url = `api/NurseRecordAll`;
      let qs = `?$filter=SchoolCode eq '${printInfo.school}'&Ryear eq '${printInfo.year}'`;

      try {
        let { Items } = await window.axios.get(url + qs);
        Items = Items.filter(
          (s) => s.Term == printInfo.term && s.Ryear == printInfo.year
        );

        let map = JSON.parse(JSON.stringify(Items));

        if (map.length) {
          let items = [];
          this.map = map[0];
          items = map[0].nurse_recordall;
          items = items.map((s) => {
            if (s.Grade == 1) {
              s.GradeName = "一";
              return s;
            } else if (s.Grade == 2) {
              s.GradeName = "二";
              return s;
            } else if (s.Grade == 3) {
              s.GradeName = "三";
              return s;
            } else if (s.Grade == 4) {
              s.GradeName = "四";
              return s;
            }
          });
          items.sort(function(a, b) {
            return a.Grade - b.Grade;
          });
          this.recommend = map[0].Recommend;
          this.createName = map[0].CreateName;
          this.allListItems = items;
        }
      } catch (e) {
        const str = e + " API: " + url;
        this.$root.$emit("dtc-server-error", str);
      }
    },
  },
  async mounted() {
    this.getData();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.dtc-main-section {
  width: 1140px;
  margin: 0 auto;
}
.dtc-header {
  border: 1px solid black;
  height: 80px;
  font-size: 24px;
  text-align: center;
}
.h2 {
  border: 1px solid black;
  border-top: none;
  height: 40px;
  font-size: 18px;
  text-align: left;
  line-height: 40px;
  padding-left: 5px;
  font-weight: bold;
  margin-bottom: 0;
}

.grid-6 {
  display: grid;
  border: 1px solid black;
  min-height: 30px;
  border-top: none;
  grid-template-columns: 90px 120px 90px 150px 480px 1fr;
  > div {
    border-right: 1px solid black;
    height: 100%;
    font-size: 16px;
    text-align: center;
    line-height: 56px;
    min-height: 56px;
  }
  > div:last-child {
    border-right: none;
  }
}

footer {
  border: 1px solid black;
  border-top: none;
  min-height: 140px;
  font-size: 13px;
  text-align: left;
  padding-left: 2px;
  padding-top: 10px;
}
.footerWord {
  padding-left: 30px;
  padding-top: 10px;
  font-size: 22px;
  .footerWordDetail {
    margin-top: 10px;
    .footerWordTime {
      display: inline-block;
      margin-left: 260px;
    }
  }
}
@media print {
  @page {
    size: A4 landscape;
  }
}
</style>
